import { Thermometer } from "@commonsku/styles";
import { Currency } from "../../../types";
import React from "react";
import styled from "styled-components";

const SalesProgressContainer = styled.div`
    position: relative;
    height: 55px;
    width: 40vw;
    min-width: 300px;
`;

const SalesProgressBar = styled(Thermometer)`
    &&& {
        font-size: 14px;
        font-weight: 600;
    }
    > * {
        &:last-child {
            > * {
                &:first-child {
                    height: 16px;
                }
                > * {
                    &:first-child {
                        height: 16px;
                    }
                }
            }
        }
    }
`;

const formatMoney = (value: number, currency) =>
    `${currency.symbol}${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

const ProgressLabel = styled.span`
    white-space: nowrap;
`;

export interface SalesProgressProps {
    salesToDate: number;
    salesTarget: number;
    currency: Currency;
}

export const SalesProgress = ({
    salesToDate,
    salesTarget,
    currency,
}: SalesProgressProps) => {
    return (
        <SalesProgressContainer>
            <SalesProgressBar
                target={salesTarget}
                value={salesToDate}
                valueLabel={() => <ProgressLabel>Sales to date: {formatMoney(salesToDate, currency)}</ProgressLabel>}
                targetLabel={() => <ProgressLabel>Annual target: {formatMoney(salesTarget, currency)}</ProgressLabel>}
            />
        </SalesProgressContainer>
    );
};
