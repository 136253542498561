import { DisplayClient, DisplayNote } from "../../../types";
import React from "react";
import { Link } from "react-router-dom";
import {
  colors,
  Text,
  EditIcon,
  CopyIcon as BaseCopyIcon,
} from "@commonsku/styles";
import styled from "styled-components";
import { setEditingDetails, showPopup } from "../../../redux/clientDetails";
import { useDispatch } from "react-redux";
import { ViewAll } from "./ViewAll";

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const HeaderText = styled(Text)`
  flex: 1;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  color: ${colors.neutrals[100]};
`;

const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${colors.teal[65]};
`;

const HeaderEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ClientTable = styled.table`
  width: 100%;
  &&& {
    border: none;
    border-collapse: separate;
    border-spacing: 7px;
  }
  tr:hover > td {
    cursor: default;
    background-color: inherit;
  }
`;

const HeaderRow = styled.tr`
  height: 47px;
`;

const StyledCol = styled.td<{ $bold?: boolean; }>`
  font-weight: ${(props) => (props.$bold ? 500 : 400)};
  min-width: 150px;
  max-width: 300px;
  white-space: nowrap;
  &&& {
    padding: 0 16px 0 0;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

const THeadText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutrals[100]};
  vertical-align: middle;
`;

const TbodyText = styled(Text) <{ $blue?: boolean; }>`
  vertical-align: middle;
  font-weight: 400;
  font-family: 'skufont-regular';
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.$blue ? colors.primary1[65] : colors.neutrals[90]};
`;

const ClientIcon = styled.img`
  vertical-align: middle;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 4px;
`;

const CopyIcon = styled(BaseCopyIcon)`
  vertical-align: middle;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 4px;
`;

interface ClientSummaryProps {
  client: DisplayClient;
  lastActivity?: DisplayNote;
}

const ClientSummaryLineOne = ({ client }: ClientSummaryProps) => (
  <tr>
    <StyledCol>
      {client.clientRep ? (
        <>
          {client.clientRep.avatarImagePath != null && (
            <ClientIcon
              src={client.clientRep.avatarImagePath.toString()}
              alt=""
            />
          )}
          <TbodyText $blue>
            {client.clientRep.firstName} {client.clientRep.lastName}
          </TbodyText>
        </>
      ) : (
        <TbodyText>
          No Client Rep
        </TbodyText>
      )}
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.status != null ? client.status.name : "No Status"}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.defaultTerms.name}</TbodyText>
    </StyledCol>
  </tr>
);

const ClientSummaryLineTwo = ({ client }: ClientSummaryProps) => (
  <tr>
    <StyledCol>
      <TbodyText>{client.defaultTax.label}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText>{client.minMargin}</TbodyText>
    </StyledCol>
    <StyledCol>
      <TbodyText $blue>
        <Link
          to={"./portal/"}
          rel="opener noreferrer"
          target="_blank"
        >
          Main Portal
        </Link>
      </TbodyText>
    </StyledCol>
  </tr>
);

export interface DetailsCardProps {
  client: DisplayClient;
}

export const DetailsCard = ({ client }: DetailsCardProps) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Header>
        <HeaderText>Details</HeaderText>
        <HeaderButtons>
          <HeaderEditIcon onClick={() => {
            dispatch(setEditingDetails(true));
            dispatch(showPopup("view-details"));
          }} />
        </HeaderButtons>
        <HeaderButtons>
          <ViewAll onClick={() => dispatch(showPopup("view-details"))}>
            View All
          </ViewAll>
        </HeaderButtons>
      </Header>
        <ClientTable>
          <tbody>
            <HeaderRow>
              <StyledCol $bold>
                <THeadText>Client Rep</THeadText>
              </StyledCol>
              <StyledCol $bold>
                <THeadText>Status</THeadText>
              </StyledCol>
              <StyledCol $bold>
                <THeadText>Default Terms</THeadText>
              </StyledCol>
            </HeaderRow>
            <ClientSummaryLineOne client={client} />
          </tbody>
        </ClientTable>
        <ClientTable>
          <tbody>
            <HeaderRow>
              <StyledCol $bold>
                <THeadText>Tax</THeadText>
              </StyledCol>
              <StyledCol $bold>
                <THeadText>Min Margin</THeadText>
              </StyledCol>
              <StyledCol $bold>
                <THeadText>Portal</THeadText>
                <CopyIcon
                  size="medium"
                  altText="Portal"
                  onClick={() =>
                    navigator.clipboard.writeText(client.portal.id)
                  }
                />
              </StyledCol>
            </HeaderRow>
            <ClientSummaryLineTwo client={client} />
          </tbody>
        </ClientTable>
    </div>
  );
};
