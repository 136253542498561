import { AddNoteIcon, AddTaskIcon, colors, H4, MeetingIcon, PhoneIcon } from "@commonsku/styles";
import { DisplayNote, NoteType } from "../../../types";
import { Unvalidated } from "../types";
import styled from "styled-components";
import React, { useState } from "react";
import { CreateNoteDialog } from "./CreateNoteDialog";

const PanelContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: #EDF2F4;
        border-radius: 8px;
        padding-top: 14px;
    }
`;

const Panel = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
`;

const PanelItem = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        gap: 14px;
        cursor: pointer;
        user-select: none;
    }
`;

const PanelItemText = styled(H4)`
    &&& {
        color: ${colors.teal[65]};
        font-size: 16px;
    }
`;

export interface ActionPanelProps {
    createNote: (newNote: Unvalidated<DisplayNote>, file?: File) => void
}

export const ActionPanel = ({ createNote }: ActionPanelProps) => {
    const [createNoteType, setCreateNoteType] = useState<NoteType | null>(null);

    const toggleNoteType = (type: NoteType) => {
        if (createNoteType === type) {
            setCreateNoteType(null);
        } else {
            setCreateNoteType(type);
        }
    }

    const onCreateNote = (newNote: Unvalidated<DisplayNote>, file?: File) => {
        createNote(newNote, file);
        setCreateNoteType(null);
    }

    return (
        <PanelContainer>
            <Panel>
                <PanelItem onClick={() => toggleNoteType('NOTE')}>
                    <AddNoteIcon size="huge" />
                    <PanelItemText>Add Note</PanelItemText>
                </PanelItem>
                <PanelItem onClick={() => toggleNoteType('TASK')}>
                    <AddTaskIcon size="huge" />
                    <PanelItemText>Add Task</PanelItemText>
                </PanelItem>
                <PanelItem onClick={() => toggleNoteType('CALL')}>
                    <PhoneIcon size="huge" />
                    <PanelItemText>Add Call</PanelItemText>
                </PanelItem>
                <PanelItem onClick={() => toggleNoteType('MEETING')}>
                    <MeetingIcon size="huge" />
                    <PanelItemText>Add Meeting</PanelItemText>
                </PanelItem>
            </Panel>
            {createNoteType && (
                <div>
                    <CreateNoteDialog
                        type={createNoteType}
                        createNote={onCreateNote}
                    />
                </div>
            )}
        </PanelContainer>
    );
}
