// Adapted from web/src/components/production-report/Tasks.js

import { Row, Col, Button, Select, UploadIcon, Dropzoned, colors, XIcon } from "@commonsku/styles";
import _ from "lodash";
import React, { useState } from "react";
import DateInput, { CSKUInput } from "../../DateInput";
import MentionTextarea from "../../MentionTextarea";
import { DisplayNote, NoteType } from "../../../types";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../../utils";
import { Unvalidated } from "../types";
import { useIdentity } from "../../../hooks";
import styled from "styled-components";
import { formatFileSize, formatName } from "../utils";
import useClientDetails from "../hooks/useClientDetails";

const styles = {
    label: {
        fontFamily: "'skufont-medium',sans-serif",
        color: '#123952',
        fontSize: '1rem',
        fontWeight: 400,
        width: '100%',
    },
    boxStyle: {
        borderBottom: '1px solid #E0E7EB',
        color: '#9A9A9A',
        padding: '10px',
        marginBottom: '16px',
        background: '#EDF2F4',
        borderRadius: '4px',
    }
};

const ActionRow = styled.div`
    display: flex;
    flex-direction: row;

    &&& {
        * {
            margin: 0;
        }
    }
`;

const ActionRowLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 8px;
`;

const ActionRowRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

const AddFileButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const RemoveFileButton = styled(Button)`
    &&& {
        border-radius: 500px;
    }
`;

const RemoveFileButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const FileName = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`;

const FileSize = styled.div`
    color: ${colors.neutrals[70]};
`;

// We don't need the "files" text from the Dropzoned component here
const FileUploadContainer = styled.div`
    aside {
        display: none;
    }
`;

const FileUploadArea = styled(Dropzoned)`
    &&& {
        width: 100%;
        cursor: pointer;
    }
`;

const UserSelect = styled(Select)`
    &&& {
        min-width: 200px;
    }
`;

export interface CreateNoteDialogProps {
    type: NoteType
    createNote: (newNote: Unvalidated<DisplayNote>, file?: File) => void
}

export const CreateNoteDialog = ({
    type,
    createNote,
}: CreateNoteDialogProps) => {
    const { user_id } = useIdentity();
    const [text, setText] = useState(null);
    const [userId, setUserId] = useState(null);
    const [contactId, setContactId] = useState(null);
    const [reminderDate, setReminderDate] = useState(null);
    const [addingFile, setAddingFile] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const { contacts } = useClientDetails();

    const contactOptions = contacts.map(contact => {
        return {
            label: formatName(contact.firstName, contact.lastName),
            value: contact.id,
        };
    });

    const users = Object.entries(useSelector((state: any) => state.entities.users))
        .map((user: any) => {
            return {
                label: formatName(user[1].user_first_name, user[1].user_last_name),
                value: user[1].user_id,
            };
        });

    const mentionUsers = Object.values(useSelector((state: any) => state.entities.users))
        .map((user: any) => {
            return {
                user_image_paths: {
                    small: user.user_avatar_image_path,
                },
                contact_first_name: user.user_first_name,
                contact_last_name: user.user_last_name,
                mask: user.mask,
            };
        })
        .filter((user: any) => user.mask && user.mask !== '');

    const handleCreateNote = () => {
        const newNote: Unvalidated<DisplayNote> = {
            type,
            parentType: 'MESSAGE',
            message: text,
            author: {
                id: user_id,
            },
            creationDate: new Date(),
            comments: [],
            pinned: false,
            reminder: (userId) ? {
                reminderUser: {
                    id: userId,
                },
                reminderDate: reminderDate ?? undefined,
                completed: false,
            } : undefined,
            files: [],
            contact: contactId ? contacts.find(contact => contact.id === contactId) : undefined,
        };

        createNote(newNote, file);
        setText('');
    };

    const handleFileUpload = (file: File) => {
        setFile(file);
        setAddingFile(false);
    }

    const TaskOptions = () => (
        <>
            <UserSelect
                name="task_for"
                placeholder="Task For"
                noMargin
                value={_.find(users, { value: userId })}
                options={users}
                onChange={(e: any) => setUserId(e.value)}
            />
            <div>
                <DateInput
                    customInput={<CSKUInput />}
                    showMonthDropdown
                    showYearDropdown
                    placeholder="Reminder Date"
                    value={reminderDate}
                    onChange={setReminderDate}
                />
            </div>
        </>
    );

    const ContactOption = () => (
        <UserSelect
            name="contact"
            placeholder="Contact"
            noMargin
            value={_.find(contactOptions, { value: contactId })}
            options={contactOptions}
            onChange={(e: any) => setContactId(e.value)}
        />
    );

    const ReminderOptions = () => (
        <>
            <UserSelect
                name="reminder_user"
                placeholder="Reminder User"
                noMargin
                value={_.find(users, { value: userId })}
                options={users}
                onChange={(e: any) => setUserId(e.value)}
            />
            <div>
                <DateInput
                    customInput={<CSKUInput />}
                    showMonthDropdown
                    showYearDropdown
                    placeholder="Reminder Date"
                    value={reminderDate}
                    onChange={setReminderDate}
                />
            </div>
        </>
    );

    const Options = () => {
        switch (type) {
            case 'TASK':
                return <TaskOptions />;
            default:
                return (
                    <>
                        <ContactOption />
                        <ReminderOptions />
                    </>
                );
        }
    };

    return (
        <Row>
            <Col padded xs>
                <MentionTextarea
                    placeholder={toTitleCase(type)}
                    value={text || ''}
                    mention_users={mentionUsers}
                    onChange={setText}
                    style={{ maxWidth: '100%' }}
                />
            </Col>
            <Col padded xs>
                <ActionRow>
                    <ActionRowLeft>
                        <Options />
                    </ActionRowLeft>
                    <ActionRowRight>
                        {file ? (
                            <RemoveFileButton size="medium" variant="secondary" onClick={() => setFile(null)}>
                                <RemoveFileButtonContent>
                                    <FileName>
                                        {file.name}
                                    </FileName>
                                    <FileSize>
                                        ({formatFileSize(file.size)})
                                    </FileSize>
                                    <XIcon />
                                </RemoveFileButtonContent>
                            </RemoveFileButton>
                        ) : (
                            <Button size="medium" variant="secondary" onClick={() => setAddingFile(true)}>
                                <AddFileButtonContent>
                                    <UploadIcon />
                                    <span>
                                        Add File
                                    </span>
                                </AddFileButtonContent>
                            </Button>
                        )}
                        <Button size="medium" onClick={handleCreateNote}>
                            Create {toTitleCase(type)}
                        </Button>
                    </ActionRowRight>
                </ActionRow>
            </Col>
            {addingFile &&
                <Col padded xs>
                    <FileUploadContainer>
                        <FileUploadArea
                            placeholder="Upload File"
                            multiple={false}
                            showDroppedFiles={true}
                            onDrop={(acceptedFiles) => {
                              if (acceptedFiles.length > 0) {
                                handleFileUpload(acceptedFiles[0]);
                              }
                            }}
                        />
                    </FileUploadContainer>
                </Col>
            }
        </Row>
    );
}
