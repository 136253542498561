import { useWindowSize } from "@commonsku/styles";
import { useLayoutEffect, useRef, useState } from "react";

export const useScrollPopup = () => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [currentScroll, setCurrentScroll] = useState(0);
    const [windowHeight] = useWindowSize();

    const scrollHeight = scrollRef.current?.scrollHeight || 0;
    const clientHeight = scrollRef.current?.clientHeight || 0;

    const handleScroll = () => {
        setCurrentScroll(scrollRef.current?.scrollTop || 0);
    }

    useLayoutEffect(handleScroll, [windowHeight]);

    const scrolledToTop = currentScroll === 0;
    // Workaround for off-by-one differences in scroll heights
    const scrolledToBottom = 
        (currentScroll >= scrollHeight - clientHeight - 1) &&
        (currentScroll <= scrollHeight - clientHeight + 1);

    return {
        scrollRef,
        currentScroll,
        handleScroll,
        scrolledToTop,
        scrolledToBottom,
    }
}
