import { DisplayContact } from "../../types";
import { formatDateFromString } from "../../utils";

/**
 * Searches for a string in the first name, last name, and email of a contact.
 * 
 * TODO: fuzzy search
*/
export const searchContacts = (query: string, contacts: readonly DisplayContact[], department = "All") => {
    let sortedContacts = [...contacts].sort((a, b) => {
        const aFullName = `${a.firstName} ${a.lastName}`;
        const bFullName = `${b.firstName} ${b.lastName}`;
        return aFullName.localeCompare(bFullName);
    });

    const lowerCaseQuery = query.toLocaleLowerCase();

    if (query !== "") {
        sortedContacts = sortedContacts.filter((contact) => {
            const fullName = `${contact.firstName} ${contact.lastName}`;
            return fullName.toLocaleLowerCase().includes(lowerCaseQuery) ||
                contact.email.toLocaleLowerCase().includes(lowerCaseQuery);
        });
    }

    if (department !== "All") {
        sortedContacts = sortedContacts.filter((contact) => {
            if (contact.department != null) {
                return department.toLocaleLowerCase().includes(contact.department.name.toLocaleLowerCase())
            }
        });
    }

    return sortedContacts
}

export const formatRelativeDate = (date: Date) => {
    let daysSince = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (daysSince === 0) {
        return "Today";
    }
    if (daysSince === 1) {
        return "Yesterday";
    }
    if (daysSince < 7) {
        return `${daysSince} days ago`;
    }
    return formatDateFromString(date.toString());
}

export const formatFileSize = (size: number) => {
    if (size < 1024) {
        return `${size} B`;
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(0)} KB`;
    } else {
        return `${(size / 1024 / 1024).toFixed(0)} MB`;
    }
}

export const formatName = (firstName?: string, lastName?: string) =>
    `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`;
