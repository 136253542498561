import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export type PhoneType =
    | 'WORK'
    | 'HOME'
    | 'CELL'
    | 'FAX'

export interface DisplayPhone {
    id: string
    type: PhoneType
    // The phone number, including the extension
    number: string
}

// Type guards
export const isPhoneType = (type: unknown): type is PhoneType => {
    return (isString(type) && ['WORK', 'HOME', 'CELL', 'FAX'].includes(type));
}

export const isDisplayPhone = (phone: unknown): phone is DisplayPhone => {
    if (
        typeof phone !== 'object' ||
        !('id' in phone) ||
        !('type' in phone) ||
        !('number' in phone)
    ) {
        return false;
    }

    return (
        isString(phone.id) &&
        isPhoneType(phone.type) &&
        isString(phone.number)
    );
}

// Validators
export const validateDisplayPhone = (phone: DisplayPhone) => {
    validateNonEmptyString(phone.id);
}
