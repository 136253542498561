import { DisplayContact } from "../../../types";
import { ContactSummary } from "../Contacts/ContactSummary";
import React, { useState } from "react";
import styled from "styled-components";
import { colors, Text, Pagination } from "@commonsku/styles";
import { Link } from "react-router-dom";
import { useScrollCarousel } from "../hooks/useScrollCarousel";
import { ScrollIndicator } from "../ScrollIndicator";
import { SearchBar } from "../SearchBar";
import { searchContacts } from "../utils";
import { ViewAll } from "./ViewAll";
import { DepartmentSelect } from "../DepartmentSelect";

const CONTACTS_SHOWN = 100;

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-height: 40px;
  &&& {
    margin-bottom: 16px;
  }
`;

const HeaderText = styled(Text)`
  flex: 1;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  color: ${colors.neutrals[100]};
`;

const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${colors.primary1[65]};
`;

const ScrollContainer = styled.div`
  position: relative;
`;

const ContactCarousel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 16px 8px;
  padding-right: 0;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ContactTable = styled.table`
  tr:hover > td {
    cursor: default;
    background-color: inherit;
  }
`;

const ContactList = styled.tr`
  display: flex;
  gap: 16px;
`;

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export interface ContactsCardProps {
  primaryContact?: DisplayContact;
  contacts: readonly DisplayContact[];
}

export const ContactsCard = ({
  primaryContact,
  contacts,
}: ContactsCardProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentDepartment, setCurrentDepartment] = useState<string>("All");
  const {
    scrollRef,
    currentPage,
    totalPages,
    handleScroll,
    calculateScroll,
    scrolledToLeft,
    scrolledToRight,
  } = useScrollCarousel();

  const contactList = primaryContact == null
    ? searchContacts(searchQuery, contacts, currentDepartment)
    : searchContacts(searchQuery, [
      primaryContact,
      ...contacts.filter(contact => contact !== primaryContact)
    ], currentDepartment);

  const onSearch = (e: React.FormEvent) => {
    e.preventDefault();

    const value = (e.target as HTMLInputElement).value;
    setSearchQuery(value);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const departmentList = [...new Set(
      contacts
        .filter(contact => contact.active && contact.department != null)
        .map(contact => contact.department.name)
  )];

  return (
    <div>
      <Header>
        <HeaderText>Contacts</HeaderText>
        <SearchBar
          query={searchQuery}
          onChange={onSearch}
          onClear={clearSearch}
        />
        <HeaderButtons>
          <DepartmentSelect
            department={currentDepartment}
            departmentList={departmentList}
            onSelectDepartment={setCurrentDepartment}
          />
          <ViewAll>
            <Link to="./contacts">View All</Link>
          </ViewAll>
        </HeaderButtons>
      </Header>
      <ScrollContainer>
        <ScrollIndicator
          $direction="left"
          $visible={!scrolledToLeft}
        />
        <ContactCarousel ref={scrollRef} onScroll={() => handleScroll(0)}>
          <ContactTable>
            <tbody>
              <ContactList>
                {contactList.length > 0 ? (
                  contactList.slice(0, CONTACTS_SHOWN).map((contact, index) => (
                    <td key={index}>
                      {primaryContact === contact ? (
                        <ContactSummary
                          contact={primaryContact}
                          isFullContact={false}
                          cardColor={colors.secondary3[20]}
                          textColor={colors.secondary3[80]}
                        />
                      ) : (
                        <ContactSummary
                          contact={contact}
                          isFullContact={false}
                        />
                      )}
                    </td>
                  ))
                ) : (
                  <NoResults>
                    <Text>No contacts found</Text>
                  </NoResults>
                )}
              </ContactList>
            </tbody>
          </ContactTable>
        </ContactCarousel>
        <ScrollIndicator
          $direction="right"
          $visible={!scrolledToRight}
        />
      </ScrollContainer>
      {scrollRef.current != null && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onChange={calculateScroll}
        />
      )}
    </div>
  );
};
