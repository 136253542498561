import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayAccountStatus {
    id: string
    name: string
}

// Type guards
export const isDisplayAccountStatus = (status: unknown): status is DisplayAccountStatus => {
    if (
        typeof status !== 'object' ||
        !('id' in status) ||
        !('name' in status)
    ) {
        return false;
    }

    return (
        isString(status.id) &&
        isString(status.name)
    );
}

// Validators
export const validateDisplayAccountStatus = (status: DisplayAccountStatus) => {
    validateNonEmptyString(status.id);
}
