import { colors } from "@commonsku/styles";
import React from "react";
import { DisplayUser } from "../../types";
import Avatar from "../helpers/Avatar";

export const UserAvatar = ({ user }: { user: DisplayUser }) => (
    <Avatar
        name={`${user.firstName} ${user.lastName}`}
        user_image_paths={user.avatarImagePath ? { small: user.avatarImagePath.toString() } : {}}
        backgroundColor={colors.teal["main"]}
        style={{
          height: 24,
          width: 24,
          fontSize: "0.75rem",
          paddingRight: 0,
        }}
    />
);
