import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, colors, Csku, Text } from "@commonsku/styles";
import { getReportConfig, ReportConfig, ReportElementSizes } from "../../report/config";
import { EReportTypes } from "../../report/routes/report_types";
import { ReportProvider } from "../../report/report_context";
import ReportFiltersContainer from "../../report/filters/ReportFiltersContainer";
import Pagination from "../../report/table/Pagination";
import ReportTable from "../../report/table/ReportTable";
import Loading from "../../report/Loading";
import { DisplayClient } from "../../../types";
import { ViewAll as BaseViewAll } from "./ViewAll";
import { useDispatch } from "react-redux";
import { showPopup } from "../../../redux/clientDetails";

const CARD_STYLE_CONSTANTS: ReportElementSizes = {
    SIDE_BAR_WIDTH: 192,
    PREFERRED_WINDOW_WIDTH: 1512,
    FILTER_CONTAINER_HEIGHT: 0, // Filter is rendered elsewhere
    TABLE_ELEMENTS_GAP: 16,
    SUMMARY_CONTAINER_HEIGHT: 0, // No summary
    TABLE_PAGE_HEADER_HEIGHT: 0, // Unnecessary
    ROW_HEIGHT: 80,
    TABLE_BOTTOM_MARGIN: 80,
    BULK_ACTION_BAR_HEIGHT: 69,
} as const;

const Header = styled.div`
    padding: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-height: 40px;
    &&& {
        margin-bottom: 16px;
    }
`;

const HeaderText = styled(Text)`
    flex: 1;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    cursor: default;
    color: ${colors.neutrals[100]};
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ViewAll = styled(BaseViewAll)`
    margin: 0 16px;
    flex-shrink: 0;
`;

const ReportFilters = styled(ReportFiltersContainer)`
    margin-bottom: 0 !important;
    margin-top: 6px !important;
`;

const NewProjectButton = styled(Button)`
    white-space: nowrap;
    margin-left: 8px !important;
`;

export interface ProjectsCardProps {
    clientId: DisplayClient['id'];
}

export const ProjectsCard = ({ clientId }: ProjectsCardProps) => {
    const dispatch = useDispatch();
    const reportConfig: ReportConfig = useMemo(
        () => ({ ...getReportConfig(EReportTypes.ClientProject) }),
        []
    );

    const defaultFilters = useMemo(
        () => ({
            client_id: clientId,
        }),
        [clientId]
    );

    return (
        <ReportProvider
            reportConfig={reportConfig}
            defaultFilters={defaultFilters}
            elementSizeParameters={CARD_STYLE_CONSTANTS}
        >
            <Header>
                <HeaderText>Projects</HeaderText>
                <HeaderActions>
                    <ReportFilters />
                    <ViewAll>
                        <Link to="./projects">View All</Link>
                    </ViewAll>
                    <NewProjectButton 
                        size="medium" 
                        onClick={() => dispatch(showPopup("create-project"))}
                    >
                        New Project
                    </NewProjectButton>
                </HeaderActions>
            </Header>
            <Csku as={"div"}>
                <ReportTable
                    headerClassName="client-project-report-table-header subreport-table-header"
                />
                <Pagination />
                <Loading />
            </Csku>
        </ReportProvider>
    );
}
