import styled from 'styled-components';

export const Tile = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 16px;
        gap: 16px;
        height: auto;
        width: auto;
        overflow: hidden;
        box-sizing: border-box;
        box-shadow: 0px 4px 7px #16344e14;
        background-color: white;
    }
`;
