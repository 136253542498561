import { LabeledCheckbox } from "@commonsku/styles";
import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    &&& {
        > * {
            margin-bottom: 0 !important;
        }
    }
`;

interface ShowInactiveProps {
    checked: boolean;
    onChange: () => void;
    labelStyle?: React.CSSProperties;
}

export const ShowInactive = ({ checked, onChange, labelStyle }: ShowInactiveProps) => {
    return (
        <CheckboxContainer>
            <LabeledCheckbox
                label="Show Inactive"
                checked={checked}
                onChange={onChange}
                labelStyle={labelStyle ?? {}}
            />
        </CheckboxContainer>
    )
}