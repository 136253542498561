import { isNumber, isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayTax {
    id: string
    label: string
    percent: number
}

// Type guards
export const isDisplayTax = (tax: unknown): tax is DisplayTax => {
    if (
        typeof tax !== 'object' ||
        !('id' in tax) ||
        !('label' in tax) ||
        !('percent' in tax)
    ) {
        return false;
    }

    return (
        isString(tax.id) &&
        isString(tax.label) &&
        isNumber(tax.percent)
    );
}

// Validators
export const validateDisplayTax = (tax: DisplayTax) => {
    validateNonEmptyString(tax.id);
}
