import styled from "styled-components";
import { SubPageHeader } from "../SubPageHeader";
import React, { useCallback, useEffect, useState } from "react";
import { ActionPanel } from "./ActionPanel";
import { colors, SkubotSpinner } from "@commonsku/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes, getClientDetailsState, pinNote, setLoading, completeTask } from "../../../redux/clientDetails";
import { DisplayNote, NoteType } from "../../../types";
import { NoteDetails } from "./NoteDetails";
import useClientDetails from "../hooks/useClientDetails";
import { Unvalidated } from "../types";
import { createAddComment, deleteMessage } from "../../../actions/message";
import { useIdentity } from "../../../hooks";
import DeleteNotePopup from "./DeleteNotePopup";
import { createMessage } from "../../../redux/messages";
import { createUploadFile } from "../../../actions/file";

const MINIMUM_NOTES_TO_FETCH = 25;

const TABS: Array<{ title: string, type: NoteType | 'all' }> = [
    { title: 'All Activity', type: 'all' },
    { title: 'Notes', type: 'NOTE' },
    { title: 'Tasks', type: 'TASK' },
    { title: 'Calls', type: 'CALL' },
    { title: 'Meetings', type: 'MEETING' },
];

const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

const Tab = styled.div<{ active?: boolean }>`
    &&& {
        font-size: 18px;
        font-weight: 600;
        padding-right: 32px;
        color: ${(props) => (props.active ? colors.neutrals["darkest"] : colors.neutrals[70])};
        cursor: pointer;
        text-decoration: ${(props) => (props.active ? "underline" : "none")};
        text-underline-offset: 1rem;
        text-decoration-color: ${colors.secondary1[50]};
        text-decoration-thickness: 8px;
    }
`;

const TabContainer = styled.div`
    &&& {
        display: flex;
        gap: 8px;
        margin-bottom: 24px;
    }
`;

const NoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActivityPage = () => {
    const { user_id } = useIdentity();
    const { client, users, contacts } = useClientDetails();
    const { notes, loading, error } = useSelector(getClientDetailsState);
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const [selectDelNote, setSelectDelNote] = useState<DisplayNote>(null);
    const dispatch = useDispatch();

    const fetchNotesWithPagination = useCallback(
        (getNewNotes = false, minimumNotes = MINIMUM_NOTES_TO_FETCH) => {
            if (loading || error) {
                return;
            }

            dispatch(setLoading(true));
            dispatch(fetchNotes({
                user_id,
                client_id: client.id,
                users,
                contacts,
                minimumNotes,
                getNewNotes,
            }));
        }, 
        [dispatch, loading, error, notes, user_id, client.id],
    );

    const handleScroll = useCallback(() => {
        const isFullyScrolled = Math.ceil(window.innerHeight + window.scrollY) >= document.body.scrollHeight;

        if (isFullyScrolled) {
            fetchNotesWithPagination();
        }
    }, [notes, fetchNotesWithPagination]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const onCreateNote = async (newNote: Unvalidated<DisplayNote>, file?: File) => {
        const data = {
            details_type: 'NOTE',
            message_text: newNote.message,
            note_type: newNote.type,
            date_reminder: newNote.reminder?.reminderDate || '',
            reminder_user: newNote.reminder?.reminderUser.id || '',
            details_parent_id: client.id,
            details_parent_type: 'CLIENT',
            contact_id: newNote.contact?.id || '',
        }

        const resp = await dispatch(createMessage(data));

        if (resp && 'note_id' in resp && file) {
            await dispatch(createUploadFile(resp.note_id, 'NOTE', file));
        }

        fetchNotesWithPagination(true, 1);
    }

    const onCreateComment = (newNote: Unvalidated<DisplayNote>, parent: DisplayNote) => {
        dispatch(createAddComment(
            newNote.message,
            parent.messageId,
            "message",
            user_id,
            '',
            {},
        ));
    }

    const toggleNotePinned = (note: DisplayNote) => {
        dispatch(pinNote({
            message_id: note.messageId,
            pinned: !note.pinned
        }));
    };

    const toggleCompleted = (note: DisplayNote) => {
        dispatch(completeTask({
            note_id: note.id,
            completed: !note.reminder.completed,
        }));
    };

    useEffect(() => {
        fetchNotesWithPagination();
    }, []);

    const selectedNotes = notes
        ?.filter((note) => activeTab.type === 'all' || (note.parentType !== 'EVENT' && note.type === activeTab.type)) ?? [];

    return (
        <ActivityContainer>
            {selectDelNote !== null && 
                <DeleteNotePopup 
                    client_id={client.id} 
                    setOpen={setSelectDelNote} 
                    note={selectDelNote}
                />
            }
            <SubPageHeader title="Activity" />
            <TabContainer>
                {TABS.map((tab) => (
                    <Tab key={tab.type} active={tab === activeTab} onClick={() => setActiveTab(tab)}>
                        {tab.title}
                    </Tab>
                ))}
            </TabContainer>
            <ActionPanel createNote={onCreateNote} />
            <NoteContainer>
                {selectedNotes.map((note) => (
                    <div key={note.id}>
                        <NoteDetails
                            note={note}
                            createComment={onCreateComment}
                            deleteNote={setSelectDelNote}
                            toggleNotePinned={toggleNotePinned}
                            toggleCompleted={toggleCompleted}
                            type={note.type}
                        />
                    </div>
                ))}
            </NoteContainer>
            {loading &&
                <SpinnerContainer>
                    <SkubotSpinner />
                </SpinnerContainer>
            }
        </ActivityContainer>
    );
}
