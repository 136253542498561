import { Theme, themeOptions } from "@commonsku/styles";
import ErrorBoundary from "../components/ErrorBoundary";
import Overlay from "../components/Overlay";
import React from "react";
import { useSelector } from "react-redux";
import { getPopups } from "../selectors";
import { GlobalStyle } from "./DashboardApp";
import withReducers from "../store/withReducers";
import { ClientRouter } from "../components/client-page/ClientRouter";
import ClientReducer from "../redux/clientDetails";
import CompanyContactReducer from "../reducers/company_contact";
import CommissionClientRateReducer from '../redux/commissionClientRates';
import '../../scss/css/react-toastify.css';

export const ClientApp = () => {
  const popups = useSelector(getPopups);

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <ClientRouter />
        <Overlay popups={popups} />
      </ErrorBoundary>
    </Theme>
  );
};

export default withReducers(ClientApp, {
  clientDetails: ClientReducer,
  companyContact: CompanyContactReducer,
  commissionClientRates: CommissionClientRateReducer
});
