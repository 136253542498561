import { colors, Text } from "@commonsku/styles";
import styled from "styled-components";

export const ViewAll = styled(Text)`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.teal[65]};
    cursor: pointer;
    a:visited {
        color: inherit;
    }
`;
