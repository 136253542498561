import React from "react";
import { DisplayClient, DisplayContact, DisplayNote } from "../../../types";
import { DetailsCard } from "./DetailsCard";
import { Tile } from "../Tile";
import styled, { createGlobalStyle } from "styled-components";
import { colors } from "@commonsku/styles";
import { ActionsMenu } from "./ActionsMenu";
import { ContactsCard } from "./ContactsCard";
import { SubPageHeader } from "../SubPageHeader";
import { SalesProgress } from "./SalesProgress";
import { ProjectsCard } from "./ProjectsCard";
import { ProfileCard } from "./ProfileCard";
import { useDispatch } from "react-redux";
import { createUpdateClient } from "../../../actions/client";
import { ActivityCard } from "./ActivityCard";

const Theme = createGlobalStyle`
    body {
        background-color: ${colors.neutrals[20]} !important;
    }

    .resku table tr:hover > td {
        background-color: unset;
    }

    .resku table tbody {
        border: none;
    }
`;

const OverviewPageContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        gap: 32px;
    }
`;

const HeaderContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin-left: auto;
    }
`;

const TileRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
`;

const DetailsTile = styled(Tile)`
    &&& {
        flex-grow: 1;
    }
`;

const ActivityTile = styled(Tile)`
    &&& {
        width: 32%;
    }
`;

export interface OverviewPageProps {
    client: DisplayClient
    contacts: readonly DisplayContact[]
    recentActivity: readonly DisplayNote[]
}

export const OverviewPage = ({
    client,
    contacts,
    recentActivity,
}: OverviewPageProps) => {
    const dispatch = useDispatch();

    const onClientProfileSave = (newProfile: string) => {
        dispatch(createUpdateClient(client.id, {
            client_profile: newProfile,
            update_remote: true,
        }));
    }

    return (
        <OverviewPageContainer>
            <Theme />
            <SubPageHeader title={client.name} largeTitle>
                <HeaderContainer>
                    {client.salesTarget > 0 &&
                        <SalesProgress
                            salesToDate={client.salesToDate}
                            salesTarget={client.salesTarget}
                            currency={client.defaultCurrency}
                        />
                    }
                    <ActionsMenu />
                </HeaderContainer>
            </SubPageHeader>
            <Tile>
                <ProfileCard
                    clientProfile={client.profile}
                    onSave={onClientProfileSave}
                />
            </Tile>
            <TileRow>
                <DetailsTile>
                    <DetailsCard client={client}/>
                </DetailsTile>
                <ActivityTile>
                    <ActivityCard activity={recentActivity}/>
                </ActivityTile>
            </TileRow>
            <Tile>
                <ContactsCard
                    primaryContact={client.primaryContact}
                    contacts={contacts}
                />
            </Tile>
            <Tile>
                <ProjectsCard clientId={client.id} />
            </Tile>
        </OverviewPageContainer>
    );
}
