import React, { useState } from "react";
import { DisplayNote, NoteType } from "../../../types";
import { Unvalidated } from "../types";
import styled from "styled-components";
import { Button, CheckboxIcon, colors, CommentIcon, EditIcon, PinIcon, TrashIcon, Typography, DownloadIcon } from "@commonsku/styles";
import { CreateNoteDialog } from "./CreateNoteDialog";
import { toTitleCase } from "../../../utils";
import { UserAvatar } from "../UserAvatar";
import { EditNoteDialog } from "./EditNoteDialog";
import { formatFileSize, formatName } from "../utils";

const NoteContainer = styled.div<{ isComment?: boolean }>`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 16px;
        ${props => props.isComment ? 'padding-top' : 'padding-bottom'}: 8px;
    }
`;

const BorderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const BorderedNote = styled.div<{ isComment?: boolean }>`
    &&& {
        width: 100%;
        ${props => props.isComment ? 'border-top' : 'border-bottom'}: 1px solid ${colors.neutrals[40]};
    }
`;

const NoteFooter = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: row;
`;

const Actions = styled.div`
    &&& {
        margin-left: auto;
        gap: 16px;
    }
`;

const NoteText = styled(Typography.Text)`
    &&& {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: ${colors.neutrals[90]};
    }
`;

const NoteSubtextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    color: ${colors.neutrals[100]};
`;

const ReminderContainer = styled.div<{ isCompleted: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: ${props => props.isCompleted ? "line-through !important" : "none"};
`;

const AuthorInfoText = styled(Typography.Text)`
    &&& {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: ${colors.neutrals[70]};
    }
`;

const CommentContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-left: 32px;
    }
`;

const CreateCommentContainer = styled.div`
    &&& {
        background-color: #EDF2F4;
        border-radius: 8px;
    }
`;

const CommentButton = styled(CommentIcon)`
    &&& {
        cursor: pointer;
    }
`;

const PinButton = styled(PinIcon)`
    &&& {
        cursor: pointer;
    }
`;

const EditButton = styled(EditIcon)`
    &&& {
        cursor: pointer;
    }
`;

const DeleteButton = styled(TrashIcon)`
    &&& {
        cursor: pointer;
    }
`;

const PinBar = styled.div<{ isPinned?: boolean }>`
    width: 5px;
    height: auto;
    background-color: ${(props) => (props.isPinned ? colors.primary1[60] : "white")};
`;

const DownloadFileButton = styled(Button)`
    &&& {
        border-radius: 500px;
        width: fit-content;
    }
`;

const DownloadFileButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const FileName = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`;

const FileSize = styled.div`
    color: ${colors.neutrals[70]};
`;

interface NoteSubtextProps {
    note: DisplayNote
    toggleComplete: (note: DisplayNote) => void
}

const NoteSubtext = ({ note, toggleComplete }: NoteSubtextProps) => {
    if (!note.reminder && !note.contact) {
        return;
    }

    return (
        <NoteSubtextContainer>
            {note.contact &&
                <div>
                    {toTitleCase(note.type)} {["CALL", "MEETING"].includes(note.type) ? "with" : "for"} {formatName(note.contact.firstName, note.contact.lastName)}
                </div>
            }
            {note.reminder &&
                <ReminderContainer isCompleted={note.reminder.completed}>
                    <CheckboxIcon selected={note.reminder.completed} onClick={() => toggleComplete(note)} />
                    {note.type === 'TASK' ? 'Task' : 'Reminder'} for {formatName(note.reminder.reminderUser?.firstName, note.reminder.reminderUser?.lastName)}{
                        note.reminder.completed
                            ? `${note.reminder.dateCompleted ? ` completed on ${note.reminder.dateCompleted.toLocaleDateString()}` : ''}`
                            : note.type === 'TASK'
                                ? `${note.reminder.reminderDate ? ` due on ${note.reminder.reminderDate.toLocaleDateString()}` : ''}`
                                : `${note.reminder.reminderDate ? ` on ${note.reminder.reminderDate.toLocaleDateString()}` : ''}`
                    }
                </ReminderContainer>
            }
        </NoteSubtextContainer>
    );
};

export interface NoteDetailsProps {
    note: DisplayNote
    createComment?: (newNote: Unvalidated<DisplayNote>, parent: DisplayNote) => void
    deleteNote: (note: DisplayNote) => void
    toggleNotePinned?: (note: DisplayNote) => void
    toggleCompleted?: (note: DisplayNote) => void
    isComment?: boolean
    type: NoteType
}

export const NoteDetails = ({
    note,
    createComment,
    deleteNote,
    toggleNotePinned,
    toggleCompleted,
    isComment = false,
}: NoteDetailsProps) => {
    const [showComments, setShowComments] = useState(note.comments.length > 0);
    const [isEditing, setIsEditing] = useState(false);
    const [comments, setComments] = useState(note.comments);
    const [hovering, setHovering] = useState(false);
    const mouseOver = () => {
        setHovering(true);
    }
    const mouseOut = () => {
        setHovering(false);
    }

    const toggleComments = () => {
        setShowComments(!showComments);
    }

    const onCreateComment = (newNote: Unvalidated<DisplayNote>) => {
        createComment(newNote, note);
        setComments([...comments, { id: '', messageId: '', ...newNote }]);
    }

    return (
        <BorderContainer>
            <PinBar isPinned={note.pinned} />
            <BorderedNote isComment={isComment}>
                <NoteContainer isComment={isComment}>
                    {isEditing ? (
                        <EditNoteDialog note={note} isDisplayed={setIsEditing}/>
                    ):(
                        <NoteText dangerouslySetInnerHTML={{ __html: note.message }} />
                    )} 
                    <NoteSubtext note={note} toggleComplete={toggleCompleted} />
                    {note.files.map(file =>
                        <DownloadFileButton
                            as="a"
                            key={file.id}
                            size="small"
                            variant="secondary"
                            target="_blank"
                            href={file.filePath}
                            download={file.displayName}
                        >
                            <DownloadFileButtonContent>
                                <DownloadIcon />
                                <FileName>{file.displayName}</FileName>
                                <FileSize>({formatFileSize(file.fileSize)})</FileSize>
                            </DownloadFileButtonContent>
                        </DownloadFileButton>
                    )}
                    <NoteFooter>
                        <AuthorInfo>
                            <UserAvatar user={note.author} />
                            <AuthorInfoText>
                                by {note.author.firstName} {note.author.lastName} on {note.creationDate.toLocaleDateString()}
                            </AuthorInfoText>
                        </AuthorInfo>
                        <Actions>
                            {!isComment &&
                                <>
                                    <CommentButton
                                        size="medium"
                                        number={0}
                                        onClick={toggleComments}
                                    />
                                    {note.noteParentType === 'CLIENT' &&
                                        <PinButton
                                            onMouseOver={mouseOver}
                                            onMouseOut={mouseOut}
                                            size="medium"
                                            unpin={note.pinned}
                                            filled={!note.pinned && hovering}
                                            onClick={() => toggleNotePinned(note)}
                                        />
                                    }
                                        <EditButton
                                            size="medium"
                                            color={colors.teal.main}
                                            onClick={() => setIsEditing(!isEditing)}
                                        />
                                </>
                            }
                            {note.noteParentType === 'CLIENT' &&
                                    
                                <DeleteButton
                                        size="medium"
                                        color={colors.errors.main}
                                        onClick={() => deleteNote(note)}
                                    />
                            }
                        </Actions>
                    </NoteFooter>
                    {showComments && (
                        <CommentContainer>
                            {comments.map(comment =>
                                <NoteDetails
                                    key={comment.id}
                                    note={comment}
                                    deleteNote={deleteNote}
                                    isComment
                                    type={comment.type}
                                />
                            )}
                            <CreateCommentContainer>
                                <CreateNoteDialog type="COMMENT" createNote={onCreateComment} />
                            </CreateCommentContainer>
                        </CommentContainer>
                    )}
                </NoteContainer>
            </BorderedNote>
        </BorderContainer>
    );
}
