import React from "react";
import { AddThirdPartyShippingAccountPopup } from "../../AddThirdPartyShippingAccountPopup";
import useClientDetails from "../hooks/useClientDetails";
import { useIdentity } from "../../../hooks";
import { DisplayCourier } from "../../../types";
import { useDispatch } from "react-redux";

interface CourierFields {
    courier_id: string
    courier_label: string
}

const mapCourierToFields = (courier: DisplayCourier): CourierFields => ({
    courier_id: courier.id,
    courier_label: courier.label,
});

export interface CreateShippingAccountPopupProps {
    onClose: () => void
}

export const CreateShippingAccountPopup = ({
    onClose,
}: CreateShippingAccountPopupProps) => {
    const { client, couriers } = useClientDetails();
    const { company_name } = useIdentity();
    const dispatch = useDispatch();
    const mappedCouriers = couriers.map(mapCourierToFields);

    return (
        <AddThirdPartyShippingAccountPopup
            onClose={onClose}
            client_id={client.id}
            dispatch={dispatch}
            client_name={client.name}
            couriers={mappedCouriers}
            distributor_name={company_name}
        />
    );
}
