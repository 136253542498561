import React from "react";
import { Button, colors, Csku, Popup, Text } from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { DisplayNote } from "../../../types";
import { toTitleCase } from "../../../utils";
import { useDispatch } from "react-redux";
import { deleteNote } from "../../../redux/clientDetails";
import { deleteMessage } from "../../../actions/message";

interface DeleteNotePopupProps {
    setOpen: (isOpen:DisplayNote) => void;
    note: DisplayNote;
    client_id: string;
}

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .popup-title {
        color: ${colors.neutrals.bodyText};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    .popup-buttons {
        margin-top: 24px;
        width: fit-content;
    }
`;

const DeleteNotePopup = ({
    setOpen,
    note,
    client_id,
}: DeleteNotePopupProps) => {
    const dispatch = useDispatch();

    const onDeleteNote = (note: DisplayNote) => {
        dispatch(deleteMessage(note.messageId, client_id));
        dispatch(deleteNote(note));
        setOpen(null);
    }

    return (
        <Popup
            overlayZIndex={BASE_ZINDEX + 101}
            zIndex={BASE_ZINDEX + 201}
            style={{ width: 446, height: "fit-content" }}
            noHeader
        >
            <StyledContainer>
                <Text className="popup-title">
                    Are you sure you want to delete this {note.type.toLocaleLowerCase()}?
                </Text>
                <Csku className="popup-buttons">
                    <Button variant="secondary" onClick={() => setOpen(null)} mr={24}>
                        Cancel
                    </Button>
                    <Button
                        variant="error"
                        onClick={() => onDeleteNote(note)}
                    >
                        Delete
                    </Button>
                </Csku>
            </StyledContainer>
        </Popup>
    );
};

export default DeleteNotePopup;
