import { isString } from "./type_guards";

export interface Currency {
    id: string
    country: string
    name: string
    symbol: string
}

// Type guards
export const isCurrency = (currency: unknown): currency is Currency => {
    if (
        typeof currency !== 'object' ||
        !('id' in currency) ||
        !('country' in currency) ||
        !('name' in currency) ||
        !('symbol' in currency)
    ) {
        return false;
    }

    return (
        isString(currency.id) &&
        isString(currency.country) &&
        isString(currency.name) &&
        isString(currency.symbol)
    );
}

// Validators
export const validateCurrency = (currency: Currency) => {
    if (currency.id.length !== 3) {
        throw new Error("Currency ID must be a 3-letter string");
    }
}
