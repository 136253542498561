import React from "react";
import styled from "styled-components";
import { ChevronIcon, colors, Dropdown, Text } from "@commonsku/styles";
import { toTitleCase } from "../../utils";

const DepartmentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px !important;
  min-width: 50px;
  color: white;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-left: 8px !important;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    position: absolute;
    margin-right: 32px;
    cursor: pointer;
    & div:nth-child(2) {
      left: auto;
      right: 0;
      border: 2px solid ${colors.primary1[60]};
      border-radius: 5px;
      overflow: hidden;
      padding: 0;
    } 
  }
`;

const DepartmentText = styled(Text)`
  font-size: 14px;
  font-weight: 300;
  color: ${colors.primary1[65]};
  line-height: 24px;
  height: 24px;
`;

const InnerDropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 224px;
`;

const DropdownText = styled(Text)`
  padding: 8px;
  width: 224px;
  min-height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.neutrals[90]};
  &:hover {
    background: ${colors.neutrals[30]};
  }
`;

interface DepartmentSelectProps {
  department: string;
  departmentList: string[]
  onSelectDepartment: (department: string) => void;
}

export const DepartmentSelect = ({ department, departmentList, onSelectDepartment }: DepartmentSelectProps) => {
  const DepartmentIcon = () => {
    return (
      <IconBox>
        <DepartmentText>{toTitleCase(department)}</DepartmentText>
        <ChevronIcon direction="down" size="medium" />
      </IconBox>
    )
  }

  return (
    <DepartmentBox>
      {department}
      <ChevronIcon color="white" size="medium" />
      <StyledDropdown icon={<DepartmentIcon />}>
        <InnerDropdownBox>
          <DropdownText onClick={() => onSelectDepartment("All")}>All</DropdownText>
          {departmentList.length > 0 && departmentList.map((dept) => (
            <DropdownText onClick={() => onSelectDepartment(dept)}>{toTitleCase(dept)}</DropdownText>
          ))}
        </InnerDropdownBox>
      </StyledDropdown>
    </DepartmentBox>
  )
}

