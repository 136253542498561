import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayTerms {
    id: string
    name: string
}

// Type guards
export const isDisplayTerms = (terms: unknown): terms is DisplayTerms => {
    if (
        typeof terms !== 'object' ||
        !('id' in terms) ||
        !('name' in terms)
    ) {
        return false;
    }

    return (
        isString(terms.id) &&
        isString(terms.name)
    );
}

// Validators
export const validateDisplayTerms = (terms: DisplayTerms) => {
    validateNonEmptyString(terms.id);
}
