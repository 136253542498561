// Adapted from web/src/components/production-report/Tasks.js
import { Row, Col, Button } from "@commonsku/styles";
import _ from "lodash";
import React, { useState } from "react";
import MentionTextarea from "../../MentionTextarea";
import { DisplayNote } from "../../../types";
import { useSelector, useDispatch } from "react-redux";
import { editNote } from "../../../redux/clientDetails";

export interface EditNoteDialogProps {
    note: DisplayNote
    isDisplayed: (display: boolean) => void
}

export const EditNoteDialog = ({
    note,
    isDisplayed,
}: EditNoteDialogProps) => {
    const [text, setText] = useState<string>(note.message);
    const dispatch = useDispatch();

    const mentionUsers = useSelector((state: any) =>
        Object.values(state.entities.users)
            .filter((user: any) => user.mask && user.mask !== '')
    );

    const handleEditNote = (note: DisplayNote, text: string) => {
        isDisplayed(false)
        dispatch(editNote({
            current_note: note,
            text: text
        }));
    };

    return (
        <Row>
            <Col padded xs>
                <MentionTextarea placeholder={note.message}
                    value={text || ''} mention_users={mentionUsers}
                    onChange={setText}
                    style={{ maxWidth: '100%' }}
                />
            </Col>
            <Col padded xs>
                <Button onClick={() => handleEditNote(note, text)} size="medium">Save</Button>
            </Col>
        </Row>
    );
}
