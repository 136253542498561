import { isString, isBool } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayCourier {
    id: string
    name: string
    label: string
    active: boolean
}

// Type guards
export const isDisplayCourier = (courier: unknown): courier is DisplayCourier => {
    if (
        typeof courier !== 'object' ||
        !('id' in courier) ||
        !('name' in courier) ||
        !('label' in courier) ||
        !('active' in courier)
    ) {
        return false;
    }

    return (
        isString(courier.id) &&
        isString(courier.name) &&
        isString(courier.label) &&
        isBool(courier.active)
    );
}

// Validators
export const validateDisplayCourier = (courier: DisplayCourier) => {
    validateNonEmptyString(courier.id);
}
