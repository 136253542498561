import { isNumber, isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayDepartment {
    id: string
    name: string
    ordering: number
}

// Type guards
export const isDisplayDepartment = (department: unknown): department is DisplayDepartment => {
    if (
        typeof department !== 'object' ||
        !('id' in department) ||
        !('name' in department) ||
        !('ordering' in department)
    ) {
        return false;
    }

    return (
        isString(department.id) &&
        isString(department.name) &&
        isNumber(department.ordering)
    );
}

// Validators
export const validateDisplayDepartment = (department: DisplayDepartment) => {
    validateNonEmptyString(department.id);
}
