import { DisplayContact, isDisplayContact } from "./contact";
import { isDate, isNumber, isString } from "./type_guards";
import { DisplayUser, isDisplayUser, validateDisplayUser } from "./user";
import { validateNonEmptyString } from "./validators";

export type NoteType =
    | 'NOTE'
    | 'TASK'
    | 'CALL'
    | 'MEETING'
    | 'COMMENT'

export type MessageParentType =
    | 'MESSAGE'
    | 'EVENT'

export type NoteParentType =
    | 'CLIENT'
    | 'JOB'
    | 'ORDER'

export type DisplayNoteContact = Omit<
    DisplayContact,
    'department' | 'noMarketing' | 'twitter' | 'facebook' | 'linkedin' | 'skype' | 'address' | 'tags' | 'phones'
>

export interface NoteReminder {
    reminderUser: DisplayUser
    reminderDate?: Date
    dateCompleted?: Date
    completed: boolean
}

export interface NoteFile {
    id: string
    displayName: string
    fileType: string
    filePath: string
    fileSize: number
}

export interface DisplayNote {
    type: NoteType
    id: string
    messageId: string
    message: string
    author: DisplayUser
    creationDate: Date
    pinned: boolean
    comments: readonly DisplayNote[]
    parentType?: MessageParentType
    noteParentType?: NoteParentType
    contact?: DisplayNoteContact
    reminder?: NoteReminder
    files: readonly NoteFile[]
}

export interface DisplayTask extends DisplayNote {
    type: 'TASK'
    dueDate: Date
    complete: boolean
}

export interface DisplayCall extends DisplayNote {
    type: 'CALL'
    callDate: Date
}

export interface DisplayMeeting extends DisplayNote {
    type: 'MEETING'
    meetingDate: Date
}

// Type guards
export const isNoteType = (type: unknown): type is NoteType => {
    return (isString(type) && ['NOTE', 'TASK', 'CALL', 'MEETING'].includes(type));
}

export const isNoteReminder = (reminder: unknown): reminder is NoteReminder => {
    if (
        typeof reminder !== 'object' ||
        !('reminderUser' in reminder) ||
        !('reminderDate' in reminder)
    ) {
        return false;
    }

    return (
        isDisplayUser(reminder.reminderUser) &&
        isDate(reminder.reminderDate)
    );
}

export const isNoteFile = (file: unknown): file is NoteFile => {
    if (
        typeof file !== 'object' ||
        !('id' in file) ||
        !('displayName' in file) ||
        !('fileType' in file) ||
        !('filePath' in file) ||
        !('fileSize' in file)
    ) {
        return false;
    }

    return (
        isString(file.id) &&
        isString(file.displayName) &&
        isString(file.fileType) &&
        isString(file.filePath) &&
        isNumber(file.fileSize)
    );
}

export const isDisplayNote = (note: unknown): note is DisplayNote => {
    if (
        typeof note !== 'object' ||
        !('type' in note) ||
        !('id' in note) ||
        !('messageId' in note) ||
        !('message' in note) ||
        !('author' in note) ||
        !('creationDate' in note) ||
        !('comments' in note) ||
        !Array.isArray(note.comments) ||
        !('files' in note) ||
        !Array.isArray(note.files)
    ) {
        return false;
    }

    return (
        isNoteType(note.type) &&
        isString(note.id) &&
        isString(note.messageId) &&
        isString(note.message) &&
        isDisplayUser(note.author) &&
        isDate(note.creationDate) &&
        note.comments.every(isDisplayNote) &&
        (!('contact' in note) || note.contact === undefined || isDisplayContact(note.contact)) &&
        (!('reminder' in note) || note.reminder === undefined || isNoteReminder(note.reminder))
    );
}

// Validators
export const validateDisplayNote = (note: DisplayNote) => {
    if (!['NOTE', 'TASK', 'CALL', 'MEETING', 'COMMENT'].includes(note.type)) {
        throw new Error("Invalid note type");
    }

    validateNonEmptyString(note.id);
    validateNonEmptyString(note.messageId);
    validateDisplayUser(note.author);
}
