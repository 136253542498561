import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayIndustry {
    id: string
    name: string
}

// Type guards
export const isDisplayIndustry = (industry: unknown): industry is DisplayIndustry => {
    if (
        typeof industry !== 'object' ||
        !('id' in industry) ||
        !('name' in industry)
    ) {
        return false;
    }

    return (
        isString(industry.id) &&
        isString(industry.name)
    );
}

// Validators
export const validateDisplayIndustry = (industry: DisplayIndustry) => {
    validateNonEmptyString(industry.id);
}
