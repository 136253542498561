/* Throws if string is not a non-empty string */
export const validateNonEmptyString = (string: string) => {
    if (string.length > 0)  return;
    throw new Error("Received an empty string");
}

/* Throws if email is not a string that matches an email pattern */
export const validateEmailString = (email: string, allowEmpty = true) => {
    const emailPattern: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;

    if (allowEmpty && email === '') return;
    if (emailPattern.test(email)) return;

    throw new Error(`'${email}' is not a valid email string`);
}
