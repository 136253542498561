import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import NoMatch from "../NoMatch";
import { ClientHome } from "./ClientHome";

export interface ClientRouterProps {
    location?: string
}

export const ClientRouter = ({ location }: ClientRouterProps) => {
    return (
        <BrowserRouter basename={location}>
            <Routes>
                <Route
                    path="/client/:clientId/*"
                    element={<ClientHome />}
                    ErrorBoundary={ErrorBoundary}
                />
                <Route
                    path={"*"}
                    element={<NoMatch location={location} />}
                    ErrorBoundary={ErrorBoundary}
                />
            </Routes>
        </BrowserRouter>
    );
}
