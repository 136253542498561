import { DisplayShippingAccount } from "../../../types";
import React from "react";
import { colors, Text } from "@commonsku/styles";
import styled from "styled-components";

const ShippingAccountCard = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 230px;
    height: fit-content;
    cursor: pointer;
    padding: 16px;
    gap: 10px;
    background: ${colors.primary1[10]};
  }
`;

const ShippingAccountText = styled(Text)<{ $bold?: boolean }>`
  text-wrap: wrap;
  font-size: ${(props) => (props.$bold ? "16px" : "14px")};
  font-weight: ${(props) => (props.$bold ? "700" : "400")};
  color: ${colors.primary1.main};
`;

export interface ShippingAccountSummaryProps {
  shippingAccount: DisplayShippingAccount;
}

export const ShippingAccountSummary = ({ shippingAccount }: ShippingAccountSummaryProps) => {
  return (
    <ShippingAccountCard data-testid={"shipping-account-card-" + shippingAccount.id}>
      <ShippingAccountText $bold>
        {shippingAccount.name ? shippingAccount.name : "Shipping Account"}
      </ShippingAccountText>
      <ShippingAccountText>
        {shippingAccount.courier.label}
      </ShippingAccountText>
      <ShippingAccountText>
        Account: {shippingAccount.number}
      </ShippingAccountText>
      {shippingAccount.postal && (
        <ShippingAccountText>
            Postal/Zip Code: {shippingAccount.postal}
        </ShippingAccountText>
      )}
    </ShippingAccountCard>
  );
};
