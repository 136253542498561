import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, colors, Csku, Popup, Text } from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import useClientDetails from "../hooks/useClientDetails";

interface DeleteClientPopupProps {
    onClose: () => void;
    onDelete: () => void;
}

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .popup-title {
        color: ${colors.neutrals.bodyText};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    .popup-buttons {
        margin-top: 24px;
        width: fit-content;
    }
`;

const DeleteClientPopup = ({
    onClose,
    onDelete,
}: DeleteClientPopupProps) => {
    const { client } = useClientDetails();

    return (
        <Popup
            overlayZIndex={BASE_ZINDEX + 101}
            zIndex={BASE_ZINDEX + 201}
            style={{ width: 446, height: "fit-content" }}
            noHeader
        >
            <StyledContainer>
                <Text className="popup-title">
                    Are you sure you want to delete {client.name}?
                </Text>
                <Csku className="popup-buttons">
                    <Button variant="secondary" onClick={onClose} mr={24}>
                        Cancel
                    </Button>
                    <Button
                        variant="error"
                        onClick={onDelete}
                    >
                        Delete
                    </Button>
                </Csku>
            </StyledContainer>
        </Popup>
    );
};

export default DeleteClientPopup;
