import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface ClientPortal {
    id: string
}

// Type guards
export const isClientPortal = (value: unknown): value is ClientPortal => {
    if (typeof value !== 'object' || !('id' in value)) {
        return false;
    }

    return isString(value.id);
}

// Validators
export const validateClientPortal = (portal: ClientPortal) => {
    validateNonEmptyString(portal.id);
}
