import { H1, colors } from "@commonsku/styles";
import React from "react";
import styled from "styled-components";

const Header = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
`;

const HeaderText = styled(H1)<{ largeTitle?: boolean }>`
    &&& {
        font-size: ${({ largeTitle }) => (largeTitle ? "32px" : "24px")};
        font-weight: 600;
        line-height: 48px;
        color: ${colors.neutrals[100]};
        margin: 0;
    }
`;

interface SubPageHeaderProps {
    title: string;
    largeTitle?: boolean;
    children?: React.ReactNode;
}

export const SubPageHeader = ({ title, children, largeTitle }: SubPageHeaderProps) => {
    return (
        <Header>
            <HeaderText largeTitle={largeTitle}>{title}</HeaderText>
            {children}
        </Header>
    );
};
