import React, { useLayoutEffect, useRef, useState } from "react";
import { Button, colors, EditIcon, Text } from "@commonsku/styles";
import styled from "styled-components";

const EMPTY_PROFILE_PLACEHOLDER = 
    "Add information such as PMS colours, thread colours, DST steps, stitch counts, billing procedures, and any other details relevant to a specific client.";

const Header = styled.div`
    padding: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-height: 40px;
    &&& {
        margin-bottom: 16px;
    }
`;

const HeaderText = styled(Text)`
    flex: 1;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    cursor: default;
    color: ${colors.neutrals[100]};
`;

const HeaderActions = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary1[65]};
`;

const HeaderEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ProfileText = styled(Text)`
    font-size: 18px;
    font-weight: 400;
    font-family: 'skufont-regular';
    line-height: 28px;
    color: ${colors.neutrals[90]};
    white-space: pre-wrap;
`;

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export interface ProfileCardProps {
    clientProfile: string;
    onSave: (newProfile: string) => void;
}

export const ProfileCard = ({ clientProfile, onSave }: ProfileCardProps) => {
    const profileIsEmpty = clientProfile === "";
    const [editing, setEditing] = useState(profileIsEmpty);
    const [newProfile, setNewProfile] = useState(clientProfile);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
        if (!textareaRef.current) {
            return;
        }
        // reset height to shrink on delete
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }, [newProfile, editing]);

    const toggleEditing = () => {
        if (editing) {
            onSave(newProfile);
        }
        setEditing(!editing);
    }

    return (
        <div>
            <Header>
                <HeaderText>Profile</HeaderText>
                <HeaderActions>
                    {!editing &&
                        <HeaderEditIcon
                            onClick={toggleEditing}
                            altText="Edit Profile"
                        />
                    }
                </HeaderActions>
            </Header>
            <ProfileText>
                {editing ? (
                    <div>
                        <textarea
                            ref={textareaRef}
                            autoFocus={!profileIsEmpty}
                            value={newProfile}
                            onChange={(e) => setNewProfile(e.target.value)}
                            onFocus={(e) => e.currentTarget.select()}
                            placeholder={EMPTY_PROFILE_PLACEHOLDER}
                        />
                        <SaveButtonContainer>
                            <Button size="medium" onClick={toggleEditing}>Save</Button>
                        </SaveButtonContainer>
                    </div>
                ) : 
                    clientProfile
                }
            </ProfileText>
        </div>
    );
}
