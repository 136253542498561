import { isString } from "./type_guards";
import { validateNonEmptyString } from "./validators";

export interface DisplayTag {
    id: string
    label: string
}

// Type guards
export const isDisplayTag = (tag: unknown): tag is DisplayTag => {
    if (
        typeof tag !== 'object' ||
        !('id' in tag) ||
        !('label' in tag)
    ) {
        return false;
    }
    
    return (
        isString(tag.id) &&
        isString(tag.label)
    );
}

// Validators
export const validateDisplayTag = (tag: DisplayTag) => {
    validateNonEmptyString(tag.id);
}
