import { useWindowSize } from "@commonsku/styles";
import { useRef, useState, useEffect } from "react";

export const useScrollCarousel = () => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [currentScroll, setCurrentScroll] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [windowWidth] = useWindowSize();

    const scrollWidth = scrollRef.current?.scrollWidth || 0;
    const clientWidth = scrollRef.current?.clientWidth || 0;

    useEffect(() => {
        setTotalPages(Math.ceil(scrollWidth / clientWidth));
    }, [windowWidth]);

    useEffect(() => {
        setCurrentPage(
            Math.floor(
                (currentScroll / (scrollWidth - clientWidth)) *
                (scrollWidth / clientWidth)
            )
        );
    }, [currentScroll, windowWidth]);

    const handleScroll = (step: number) => {
        let scrollAmount: number = 0;
        const slideTimer = setInterval(() => {
            if (scrollRef.current != null) {
                scrollRef.current.scrollLeft += step;
                scrollAmount += Math.abs(step);
                if (scrollAmount >= 100) {
                    clearInterval(slideTimer);
                }
                setCurrentScroll(scrollRef.current.scrollLeft);
            }
        }, 25);
    };

    const calculateScroll = (page: number) => {
        if (scrollRef.current == null) {
            return;
        }
        const targetScrollPosition = scrollWidth * (page / totalPages);
        const scrollTo = targetScrollPosition - currentScroll;
        handleScroll(scrollTo);
    };

    const scrolledToLeft = currentScroll === 0;
    const scrolledToRight = currentScroll === scrollWidth - clientWidth;

    return {
        scrollRef,
        currentScroll,
        currentPage,
        totalPages,
        handleScroll,
        calculateScroll,
        scrolledToLeft,
        scrolledToRight,
    };
};
