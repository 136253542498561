import React from "react";
import { ClientSubPage } from "./types";
import { Link, To, useMatch, useResolvedPath } from "react-router-dom";
import { colors, H4, Text } from "@commonsku/styles";
import styled from "styled-components";

interface SidebarRoute {
    title: ClientSubPage
    relativePath: To
}

const ROUTES: SidebarRoute[] = [
    { title: "Overview", relativePath: "." },
    { title: "Activity", relativePath: "./activity" },
    { title: "Contacts", relativePath: "./contacts" },
    { title: "Addresses", relativePath: "./addresses" },
    { title: "Projects", relativePath: "./projects" },
    /* { title: "Shops", relativePath: "./shops" }, */
    { title: "Library", relativePath: "./library" },
];

const Sidebar = styled.div`
    background-color: ${colors.teal[80]};
`;

const SidebarHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px 8px !important;
    gap: 10px;
`;

const SidebarHeaderText = styled(H4)`
    color: ${colors.teal[20]} !important;
    font-size: 16px !important;
    margin-bottom: 0 !important;
`;

const SidebarItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const RegularItem = styled(Link)`
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    margin-left: 8px;
`;

const SelectedItem = styled(RegularItem)`
    background-color: ${colors.teal[90]} !important;
    border-radius: 50px 0 0 50px;
`;

const LinkText = styled(Text)`
    color: ${colors.white} !important;
    margin-left: 14px;
`;

const SidebarItem = ({ title, relativePath }: SidebarRoute) => {
    const resolved = useResolvedPath(relativePath);
    const isSelected = useMatch(resolved.pathname);
    const Item = isSelected ? SelectedItem : RegularItem;

    return (
        <Item to={relativePath}>
            <LinkText>{title}</LinkText>
        </Item>
    );
};

export const ClientSidebar = () => {
    return (
        <Sidebar>
            <SidebarHeader>
                <SidebarHeaderText>
                    Client
                </SidebarHeaderText>
            </SidebarHeader>
            <SidebarItems>
                {ROUTES.map(route => (
                    <SidebarItem key={route.title} {...route} />
                ))}
            </SidebarItems>
        </Sidebar>
    );
};
